import React, { useState } from "react";
import Styles from "../Styles/fgcdata.module.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backgroundrewardimg from "../Assets/FGC/fgcbackground.webp";
import Submitbtn from "../Assets/FGC/submit.webp";

function Fgcscoreboarddata() {
  const [inputValue, setInputValue] = useState("");
  const [userName, setUserName] = useState("");
  const [userScore, setUserScore] = useState("");
  const [toastId, setToastId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for button disabling

  const showToast = (message, type = "error", callback) => {
    const options = {
      className: "",
      onClose: callback,
    };
    if (type === "success") {
      options.className = "toastSuccess";
    }
    if (!toast.isActive(toastId)) {
      const id = toast[type](message, options);
      setToastId(id);
    }
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length === 4) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/game_results/get_specificUser/${value}`
        );
        console.log(response);
        if (response.data && response.data.data) {
          const userData = response.data.data;
          setUserName(userData.name || "");
          setUserScore(userData.score || "");
        } else {
          showToast(response.data.message || "User not found", "error");
          setUserName("");
          setUserScore("");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        showToast("Error fetching user data", "error");
        setUserName("");
        setUserScore("");
      }
    } else {
      setUserName("");
      setUserScore("");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Disable button while submitting

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/game_results/store_Result`,
        {
          uniqueCode: inputValue,
          score: userScore,
          programNum: 3,
        }
      );
      if (response.status) {
        showToast("Score submitted successfully", "success", () => {
          setInputValue("");
          setUserName("");
          setUserScore("");
        });
      } else {
        showToast(response.data.message || "Failed to store score", "error");
      }
    } catch (error) {
      console.error("Error storing score:", error);
      showToast("Please enter all the fields!", "error");
    } finally {
      setIsSubmitting(false); // Re-enable button after submission
    }
  };

  return (
    <div className={Styles.rewardhome}>
      <ToastContainer />
      <div className={Styles.backgroundreward}>
        <img src={Backgroundrewardimg} alt="Background" />
      </div>
      <div className={Styles.inputcontainer}>
        <form
          className={Styles.inputformmaincontainer}
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <div className={Styles.inputformchild}>
            <div className={Styles.inputbg}>
              <input
                className={Styles.inputcode}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>

            <div className={Styles.inputboxbgname}>
              <input
                className={Styles.inputcodename}
                type="text"
                value={userName}
                readOnly
              />
            </div>

            <div className={Styles.inputbgscore}>
              <input
                className={Styles.inputcode}
                type="text"
                value={userScore}
                onChange={(e) => setUserScore(e.target.value)}
              />
            </div>
          </div>

          <div className={Styles.inputsubmitbutton}>
            <button
              className={Styles.submitbtn}
              type="submit"
              disabled={isSubmitting} // Disable button based on state
            >
              <img src={Submitbtn} alt="Submit" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Fgcscoreboarddata;
