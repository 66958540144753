import React, { useRef, useEffect, useState } from "react";
import Styles from "../Contact/Styles/contact.module.scss";
import Swal from "sweetalert2";
import axios from "axios";
import Contactmainlogo from "../Assets/contact/logo.webp";
import Lineimg from "../Assets/contact/Background/line.webp";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

function Login() {
  const [email, setEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [ButtonState, setButtonState] = useState(false);
  const dispatch = useDispatch();

  const form = useRef();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> User Name Cannot Be Empty</div>',
        timer: 4000,
      });
    } else if (password.length < 5) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">Password Should Be at Least 5 Characters Long</div>',
        timer: 4000,
      });
    } else {
      setButtonState(true);
      Swal.fire({
        title: "",
        showCloseButton: false,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
            <div style="text-align: center;">
              <div class="loader"></div>
              <div style="color: white;">Login...</div>
            </div>
          `,
        timer: 4000, // Optional timer or you can remove this line
      });

      const API = process.env.REACT_APP_API_URL;

      console.log(email, password);

      const login = async () => {
        try {
          const response = await axios.post(`${API}/api/admin/login`, {
            email,
            password,
          });

          console.log(response.data, "response.data");

          if (!response.data.err) {
            dispatch({
              type: "admin",
              payload: {
                login: true,
                details: response.data.details,
              },
            });
            await Swal.fire({
              title: "Login Successful!",
              icon: "success",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              showConfirmButton: false,
              timer: 1500,
            });
            navigate("/home");
          } else {
            Swal.fire({
              title: "Oops...",
              icon: "error",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;"> Login Failed</div>',
              showConfirmButton: false,
              timer: 1500,
            });
            console.log("Some error occurred");
          }
        } catch (err) {
          let errorMessage = "Something Went Wrong!";
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              errorMessage = err.response.data.message;
            } else {
              errorMessage = `Error: ${err.response.status}`;
            }
          } else if (err.request) {
            errorMessage = "No response received from the server.";
            console.log(err.request);
          } else {
            errorMessage = err.message;
          }
          Swal.fire({
            title: "Oops...",
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
            html: `<div style="color: white;">${errorMessage}</div>`,
            showConfirmButton: false,
            timer: 4000,
          });
        } finally {
          setButtonState(false);
        }
      };

      login();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={Styles.contactcolorbg}>
      <div className={Styles.contactsection}>
        <div className={Styles.contactimgmain}>
          <img src="" style={{visibility:"hidden"}} />
        </div>

        {/* linesection */}
        <div className={Styles.contactviewcontainerline}>
          <img src={Lineimg} />
        </div>
        {/* ends */}

        <form
          ref={form}
          className={Styles.contactform}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={Styles.contactforminputmain}>
            <div className={Styles.contactopeninghead}>
              <h1>Administration</h1>
            </div>
          </div>

          <div className={Styles.contactforminputmain}>
            <span className={Styles.inputslide} />
            <input
              className={Styles.inputNameEmail}
              type="email"
              id="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={30}
              minLength={1}
              autoComplete="off"
              name="email"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <span className={Styles.inputslide} />
            <input
              className={Styles.inputNameEmail}
              type="password"
              id="user_password"
              placeholder="Password"
              value={password}
              autoComplete="off"
              onChange={(e) => SetPassword(e.target.value)}
              maxLength={30}
              minLength={1}
              name="user_password"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <button
              disabled={ButtonState}
              className={Styles.submitbtn}
              type="submit"
            ></button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
