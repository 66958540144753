import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { format } from 'date-fns';
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function DataView() {
  const [data, setData] = useState([]);
  const [resetData, setResetData] = useState([]);

  const handleExportClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/game_results/all_result`
      );
      const usersWithScores = response.data.completedUsers;
      const usersWithNotCompletedAll = response.data.notCompletedUsers;
  
      console.log(usersWithScores, "usersWithScores");
      console.log(usersWithNotCompletedAll, "usersWithNotCompletedAll");
  
      const combinedUsers = [...usersWithScores, ...usersWithNotCompletedAll];
  
      if (!combinedUsers || combinedUsers.length === 0) {
        toast.info("No data available in user database", {});
        return;
      }
  
      const formattedData = combinedUsers.map((item) => {
        // Extract programs information
        const programs = item.user.programs || [];
        const programData = programs.reduce((acc, program) => {
          acc[`programNum${program.programNum}`] = program.score || 0; // Default to 0 if score is undefined
          return acc;
        }, {});
  
        

        const formatDateTime = (dateString) => {
          return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
        };

        return {
          Date: formatDateTime(item.user.createdAt),
          Id: item.user._id,
          UniqueCode: item.user.uniqueCode,
          Name: item.user.name,
          Email: item.user.email,
          Gender: item.user.gender,
         // Default to 0 if sumOfScores is undefined
          FpsGame: programData.programNum1 || 0, // Correctly map the scores based on programNum
          FgcGame: programData.programNum3 || 0, // Correctly map the scores based on programNum
          MobaApm: programData.programNum2 || 0, // Correctly map the scores based on programNum
          Score: item.sumOfScores || 0, 
          CompletedAllPrograms: item.completedAllPrograms ? "Yes" : "No"
        };
      });
  
      // Create CSV string
      const csvRows = [];
      const headers = Object.keys(formattedData[0]);
      csvRows.push(headers.join(","));
      formattedData.forEach((row) => {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      });
      const csvString = csvRows.join("\n");
  
      // Create and download the CSV file
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "exported_data.csv");
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Data exported successfully!", {});
    } catch (error) {
      console.error("Error exporting collection:", error);
      toast.error("Failed to export data", {});
    }
  };
  
  const handleResetClick = async () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Please export all data before resetting. This action will reset all data. This cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/data/reset_Collection`
          );
          setResetData(response.data.data);
          toast.success("Collection reset successfully!", {});
        } catch (error) {
          console.error("Error resetting collection:", error);
          toast.error("Failed to reset collection", {});
        }
      }
    });
  };

  return (
    <div className="exportdatahomecontainer">
      <div className="exportlogocontainer">
      <img src="" style={{visibility:"hidden"}} />
      </div>
      <div className="exportdatamain">
        <div>
          <button className="exportbtn" onClick={handleExportClick}>
            Export All
          </button>
        </div>
        <div>
          <button className="exportbtn" onClick={handleResetClick}>
            Reset All Boards
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DataView;
