import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "./axios";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Login Page

import Login from "./Login/Login";

//Contact and Fgcdata Pages

import Contact from "./Contact/Contact";
import HomeViewEn from "./View/HomeViewEn";
import HomeViewAr from "./View/HomeViewAr";
import Fgcscoreboarddata from "./Fgcscoreboard/Fgcscoreboarddata";

//View Pages

import ViewUser from "./View/ViewUser";
import ViewEmail from "./View/ViewEmail";
import DataView from "./View/DataView";

//All Boards

import FpsScoreboard from "./Fpsscoreboard/Fpsscoreboard";
import FgcScoreboard from "./Fgcscoreboard/Fgcscoreboard";
import MobaapmScoreboard from "./Mobaapmscoreboard/Mobaapmscoreboard";
import MainLeaderboard from "./Mainleaderboard/Mainleaderboard";
import MainLeaderboardBox from "./Mainleaderboard/Mainleaderboardbox";
import UnprotectedRoute from "./UnProtectedRoute";
import PrivateRoute from "./PrivateRoute";

function App() {
  const { refresh, admin } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {

    setCheckingAuth(true);
    const API = process.env.REACT_APP_API_URL;
    axios
      .get(`${API}/api/admin/auth`,{withCredentials:true})
      .then((response) => {
        console.log("ADMIN: ", response.data);
        dispatch({
          type: "admin",
          payload: {
            login: response.data.login,
            details: response.data.details,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setCheckingAuth(false));
  }, [refresh, dispatch]);

  console.log(admin, "admin");

  if (checkingAuth) {
    return <div className="loading">  <h1>Loading...</h1></div>
  }
  return (
    <Router>
      <div className="mainhomecontainer">
        <Routes>
          <Route path="" element={<UnprotectedRoute />}>
            <Route path="/" element={<Login />} />    
              
          </Route>
          <Route path="" element={<PrivateRoute />}>
          <Route path="/home" element={<HomeViewEn />} />
            <Route path="/ar" element={<HomeViewAr />} />
            <Route path="/registration" element={<Contact />} />
            <Route path="/view-user" element={<ViewUser />} />
            <Route path="/view-email" element={<ViewEmail />} />  
            <Route path="/exportdata" element={<DataView />} />
            <Route path="/fpsscoreboard" element={<FpsScoreboard />} />
            <Route path="/fgcscoreboard" element={<FgcScoreboard />} />
            <Route path="/fgcscoreentry" element={<Fgcscoreboarddata />} />
            <Route path="/mobaapmscoreboard" element={<MobaapmScoreboard />} />
            <Route path="/mainleaderboard" element={<MainLeaderboard />} />
            <Route
              path="/mainleaderboardsecondscreen"
              element={<MainLeaderboardBox />}
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
