import React, { useState, useEffect } from "react";
import Axios from "axios";
import "../Styles/leaderboard.scss";
import BgLVVideo from "../Assets/Leaderboard/Bglargevertical.mp4";
import BgMVVideo from "../Assets/Leaderboard/Bgvertical.mp4";
import BgLVideo from "../Assets/Leaderboard/Bglandscape.mp4";

//All Scoreboard Data

const MainLeaderboard = () => {
  const [videoUrl, setVideoUrl] = useState("");

  const getVideoUrl = (width, height) => {
    const isLandscape = width > height;

    if (isLandscape) {
      if (width < 1200) {
        return BgLVideo;
      } else {
        return BgLVideo;
      }
    } else {
      if (height < 1200) {
        return BgMVVideo;
      } else {
        return BgLVVideo;
      }
    }
  };

  useEffect(() => {
    const updateVideoUrl = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const url = getVideoUrl(width, height);
      setVideoUrl(url);
    };

    updateVideoUrl(); // Set initial value

    window.addEventListener("resize", updateVideoUrl);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateVideoUrl);
    };
  }, []);

  //Get data from server
  const [data, setData] = useState([]);
  const Api = `${process.env.REACT_APP_API_URL}/api/game_results/get_finalResult`;
  const getdata = async () => {
    try {
      let response = await Axios.get(Api);
      setData(response.data.usersWithSumOfScores);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  const sortedData = [...data].sort((a, b) => {
    return Number(b.scorevalue) - Number(a.scorevalue);
  });

  const latestData = sortedData.slice(0, 10);

  console.log(latestData);

  return (
    <div className="Allleadermain-container">
      <video
        className="videocontainer"
        controls={false}
        width="100%"
        src={videoUrl}
        poster=""
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        controlsList="nodownload"
      />

      <div className="Allleaderbgstick">
        <div className="Allleaderlogocontainer">
          <h1>
            <span id="arabictext">لوحة المتصدرين الجماعية</span> <br />
            COMMUNAL LEADERBOARD{" "}
          </h1>
        </div>

        <div className="Allleadersubheadingcontainer">
          <div>
            {/* <p className="arabictext"> اسم</p> */}
            <p>NAME</p>
          </div>

          <div>
            {/* <p className="arabictext"> نتيجة</p> */}
            <p>SCORE</p>
          </div>
        </div>

        <div className="Allmainscoreboardcontainer">
          {latestData.map((item, index) => (
            <div key={item.user.uniqueCode} className="Allmainscoreboardrow">
              <div className="Allcontainerone">{index + 1}</div>
              <div className="Allseparator"></div>
              <div className="Allcontainertwo">{item.user.name}</div>
              <div className="Allseparator"></div>
              <div className="Allcontainerthree">{item.sumOfScores}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainLeaderboard;
