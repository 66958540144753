import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback,
} from "react";
import Styles from "./Styles/contact.module.scss";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import Lineimg from "../Assets/contact/Background/line.webp";

function Contact() {
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [gender, setGender] = useState(""); // Added gender state
  const [image, SetImage] = useState(null);
  const [ImagePreview, SetImagePreview] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [MaleSelected, setMaleSelected] = useState(false);
  const [FemaleSelected, setFemaleSelected] = useState(false);
  const [ButtonState, setButtonState] = useState(false);

  const fileInputRef = useRef(null);

  const form = useRef();
  const navigate = useNavigate();
  const webcamRef = useRef(null);

  const isEnglish = (text) =>
    /^[A-Za-z0-9@.,;!#$%&'()*+\/=?^_`{|}~\s-]*$/.test(text);

  const Malehandleselector = () => {
    setMaleSelected(true);
    setFemaleSelected(false);
  };

  const Femalehandleselector = () => {
    setMaleSelected(false);
    setFemaleSelected(true);
  };

  const validateEnglishInput = (e, setState) => {
    const value = e.target.value;
    if (!isEnglish(value)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Type Only in English</div>',
        timer: 2000,
      });
    } else {
      setState(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name.trim() || !email.trim() || !gender) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
         
            <div>Please Fill All the Fields</div>
          
        `,
        timer: 4000,
      });

      return;
    }

    if (!isEnglish(name) || !isEnglish(email)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Fill in English Only</div>',
        timer: 4000,
      });
    } else if (name.trim() === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Name Cannot Be Empty</div>',
        timer: 4000,
      });
    } else if (email.length < 5) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">Email Should Be at Least 5 Characters Long</div>',
        timer: 4000,
      });
    } else if (email.split("").filter((x) => x === "@").length !== 1) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">Email Should Contain  @</div>',
        timer: 4000,
      });
    } else if (email.indexOf(".") === -1) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Email Should Contain at Least One Dot</div>',
        timer: 4000,
      });
    } else if (gender === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Select a Gender</div>',
        timer: 4000,
      });
    } else {
      setButtonState(true);
      Swal.fire({
        title: "",
        showCloseButton: false,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
          <div style="text-align: center;">
            <div class="loader"></div>
            <div style="color: white;">Submitting Your Data...</div>
          </div>
        `,
        timer: 4000, // Optional timer or you can remove this line
      });
      const API = process.env.REACT_APP_API_URL;
      axios
        .post(
          `${API}/api/users/signup`,
          { name, email, gender, image },
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          setButtonState(false);

          if (!response.data.err) {
            Swal.fire({
              title: "Registration Successful!",
              icon: "success",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;">Please Check Your Registered Email</div>',
              showConfirmButton: false,
              timer: 1500,
            });

            navigate("/view-user", { state: { user: response.data } });
          } else {
            setButtonState(false);
            Swal.fire({
              title: "Oops...",
              icon: "error",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;"> Registartion Failed</div>',
              showConfirmButton: false,
              timer: 1500,
            });
            console.log("Some error occurred");
          }
        })
        .catch((err) => {
          setButtonState(false);
          let errorMessage = "Something Went Wrong!";
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              if (err.response.data.message === "Email already registered") {
                errorMessage = "Email already registered";
              } else {
                errorMessage = err.response.data.message;
              }
            } else {
              errorMessage = `Error: ${err.response.status}`;
            }
          } else if (err.request) {
            errorMessage = "No response received from the server.";
            console.log(err.request);
          } else {
            errorMessage = err.message;
          }
          Swal.fire({
            title: "Oops...",
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
            html: `<div style="color: white;">${errorMessage}</div>`,
            showConfirmButton: false,
            timer: 4000,
          });
        });
    }
  };

  const generateRandomFileName = (extension) => {
    return `image_${Date.now()}_${Math.floor(
      Math.random() * 1000
    )}.${extension}`;
  };

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], generateRandomFileName("jpg"), {
            type: "image/jpeg",
          });
          SetImage(file);
          SetImagePreview(URL.createObjectURL(file));
          setShowPreview(true);
          setShowWebcam(false);
        });
    }
  }, [webcamRef]);

  const handleLabelClick = (e) => {
    e.preventDefault();
    if (showWebcam) {
      captureImage();
    } else {
      setShowWebcam(true);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      SetImage(file);
      SetImagePreview(fileUrl);
      setShowPreview(true);
      setShowWebcam(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videoConstraints = {
    facingMode: "user", // Use front camera
  };

  return (
    <div className={Styles.contactcolorbg}>
      <div className={Styles.contactsection}>
        {/* linesection */}
        <div className={Styles.contactviewcontainerline}>
          <img src={Lineimg} />
        </div>
        {/* ends */}

        <form
          ref={form}
          className={Styles.contactform}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={Styles.contactforminputmain}>
            <div className={Styles.contactopeninghead}>
              <h1>START YOUR</h1>
              <h1>PATH TO PRO</h1>
              <br />
            </div>
          </div>

          <div className={Styles.contactforminputmain}>
            <span className={Styles.inputslide} />
            <input
              className={Styles.inputNameEmail}
              type="text"
              id="user_name"
              placeholder="NAME الإسم"
              onChange={(e) => validateEnglishInput(e, SetName)}
              maxLength={30}
              minLength={1}
              autoComplete="off"
              name="user_name"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <span className={Styles.inputslide} />
            <input
              className={Styles.inputNameEmail}
              type="email"
              id="user_email"
              placeholder="EMAIL الايميل"
              autoComplete="off"
              onChange={(e) => validateEnglishInput(e, SetEmail)}
              maxLength={30}
              minLength={1}
              name="user_email"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <input
              type="radio"
              style={{ visibility: "hidden" }}
              className={Styles.inputGenderboxone}
              onClick={Malehandleselector}
              id="Male"
              name="gender"
              value="Male"
              onChange={(e) => setGender(e.target.value)}
            />
            <label
              htmlFor="Male"
              className={
                MaleSelected ? Styles.labelMaleSelected : Styles.labelMale
              }
            ></label>

            <input
              type="radio"
              style={{ visibility: "hidden" }}
              className={Styles.inputGenderboxtwo}
              id="Female"
              onClick={Femalehandleselector}
              name="gender"
              value="Female"
              onChange={(e) => setGender(e.target.value)}
            />
            <label
              htmlFor="Female"
              className={
                FemaleSelected ? Styles.labelFemaleSelected : Styles.labelFemale
              }
            ></label>
          </div>

          <div className={Styles.contactforminputmain}>
            <label
              className={Styles.labelUpload}
              htmlFor="user_image"
              onClick={handleLabelClick}
            >
              {showPreview && (
                <img
                  src={ImagePreview}
                  alt="Preview"
                  className={Styles.previewImage}
                />
              )}
              {showWebcam && (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  className={Styles.webcam}
                />
              )}
            </label>
            <input
              type="file"
              id="user_image"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className={Styles.contactforminputmain}>
            <button
              type="button"
              className={Styles.uploadbtn}
              onClick={handleUploadClick}
            ></button>
          </div>

          <div className={Styles.contactforminputmain}>
            <button
              disabled={ButtonState}
              className={Styles.submitbtn}
              type="submit"
            ></button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
