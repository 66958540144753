import React from "react";
import { useLocation } from "react-router-dom";
import Styles from "../Styles/viewuser.module.scss";
import Avatar from "react-avatar";
import Lineimg from "../Assets/View/Background/viewemailline.webp";
import DefaultAvatar from "../Assets/View/n_avatar@2x.webp"; // Import the default avatar image

const ViewUser = () => {
  const { state } = useLocation();
  const { user } = state || {};
  console.log(user, "user");

  if (!user.data) {
    return (
      <div className={Styles.loadingmaincontainer}>
        <div className={Styles.loadingcontainer}>
          <h1>No user data found</h1>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.viewcolorbg}>
      <div key={user.data._id} className={Styles.viewusersection}>
        <div className={Styles.viewuserimgmain}>
        <img src="" style={{visibility:"hidden"}} />
        </div>

        {/* linesection */}
        <div className={Styles.viewcontainerline}>
          <img src={Lineimg} />
        </div>
        {/* ends */}

        <div className={Styles.viewuserform}>
          <div className={Styles.viewforminputmain}>
            <div className={Styles.viewopeninghead}>
              <h2>START YOUR</h2>
              <h1>PATH TO PRO</h1>
            </div>
          </div>

          <div className={Styles.viewforminputmain}>
            <div className={Styles.viewavtarcontainer}>
              <Avatar
                size="110"
                round={true}
                src={user.data.avatar || DefaultAvatar}
              />
            </div>
          </div>

          <div className={Styles.viewforminputmain}>
            <div className={Styles.viewnamecontainer}>
              <div className={Styles.viewname}>
                <h1>{user.data.name}</h1>
              </div>
            </div>
          </div>

          <div className={Styles.viewforminputmain}>
            <div className={Styles.viewqrcontainer}>
              {user.data.qrcode && <img src={user.data.qrcode} alt="QR Code" />}
            </div>
          </div>

          <div className={Styles.viewforminputmain}>
            <div className={Styles.viewcodecontainer}>
              <h2> الرمز الفريد الخاص بك</h2>
              <h2>YOUR UNIQUE CODE</h2>
              <h1>{user.data.uniqueCode}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
