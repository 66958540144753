import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Styles from "../Styles/viewemail.module.scss";
import Avatar from "react-avatar";
import ScoreCardNum from "../Assets/View/score_num.webp";
import FPS from "../Assets/View/FPS.webp";
import APM from "../Assets/View/APM.webp";
import FGC from "../Assets/View/FGC.webp";
import Lineimg from "../Assets/View/Background/viewemailline.webp";
import DefaultAvatar from "../Assets/View/n_avatar@2x.webp"; // Import the default avatar image

//page view from mail
const ViewEmail = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const uniqueCode = queryParams.get("code");

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [FPSDATA, setFPS] = useState(0);
  const [APMDATA, setAPM] = useState(0);
  const [FGCDATA, setFGC] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      const API = process.env.REACT_APP_API_URL;

      try {
        const response = await axios.get(
          `${API}/api/users/view_mail?code=${uniqueCode}`
        );
        if (response.status === 200) {
          setUserData(response.data);
        } else {
          console.error("Failed to fetch user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (uniqueCode) {
      fetchUserData();
    }
  }, [uniqueCode]);

  useEffect(() => {
    if (userData && userData.data && userData.data.programs) {
      userData.data.programs.forEach((program) => {
        if (program.programNum === 1) {
          setFPS(program.score);
        } else if (program.programNum === 2) {
          setAPM(program.score);
        } else if (program.programNum === 3) {
          setFGC(program.score);
        }
      });
    }
  }, [userData]);

  if (!uniqueCode) {
    return (
      <div className={Styles.loadingmaincontainer}>
        <div className={Styles.loadingcontainer}>
          <h1>No unique code found in URL</h1>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={Styles.loadingmaincontainer}>
        <div className={Styles.loadingcontainer}>
          <h1>Loading...</h1>
        </div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className={Styles.loadingmaincontainer}>
        <div className={Styles.loadingcontainer}>
          <h1>Error fetching user data</h1>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.viewcolorbg}>
      <div key={userData._id} className={Styles.emailviewusersection}>
        <div className={Styles.emailviewuserimgmain}>
        <img src="" style={{visibility:"hidden"}} />
        </div>

        {/* linesection */}
        <div className={Styles.viewcontainerline}>
          <img src={Lineimg} />
        </div>
        {/* ends */}

        <div className={Styles.emailviewuserform}>
          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewopeninghead}>
              <h2>START YOUR</h2>
              <h1>PATH TO PRO</h1>
            </div>
          </div>

          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewavtarcontainer}>
              <Avatar
                size="110"
                round={true}
                src={userData.data.avatar || DefaultAvatar}
              />
            </div>
          </div>

          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewnamecontainer}>
              <div className={Styles.emailviewname}>
                <h1>{userData.data.name}</h1>
              </div>
            </div>
          </div>

          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewcodecontainer}>
              <h2> بطاقة النتائج الخاصة بك</h2>
              <h2>YOUR SCORE CARD</h2>
            </div>
          </div>

          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewscorecontainer}>
              <div className={Styles.emailformscoremain}>
                <div className={Styles.emailscorecardentry}>
                  <img
                    width="100px"
                    src={ScoreCardNum}
                    className={Styles.emailscorepartslide}
                    alt="Score Card"
                  />
                  <div className={Styles.emailscorecardentrydata}>
                    <h2>{FPSDATA}</h2>
                  </div>
                </div>

                <img
                  width="160px"
                  src={FPS}
                  className={Styles.emailscorenamepartslide}
                  alt="FPS"
                />
              </div>
            </div>
          </div>

          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewscorecontainer}>
              <div className={Styles.emailformscoremain}>
                <div className={Styles.emailscorecardentry}>
                  <img
                    width="100px"
                    src={ScoreCardNum}
                    className={Styles.emailscorepartslide}
                    alt="Score Card"
                  />
                  <div className={Styles.emailscorecardentrydata}>
                    <h2>{APMDATA}</h2>
                  </div>
                </div>

                <img
                  width="160px"
                  src={APM}
                  className={Styles.emailscorenamepartslide}
                  alt="APM"
                />
              </div>
            </div>
          </div>

          <div className={Styles.emailviewforminputmain}>
            <div className={Styles.emailviewscorecontainer}>
              <div className={Styles.emailformscoremain}>
                <div className={Styles.emailscorecardentry}>
                  <img
                    width="100px"
                    src={ScoreCardNum}
                    className={Styles.emailscorepartslide}
                    alt="Score Card"
                  />
                  <div className={Styles.emailscorecardentrydata}>
                    <h2>{FGCDATA}</h2>
                  </div>
                </div>

                <img
                  width="160px"
                  src={FGC}
                  className={Styles.emailscorenamepartslide}
                  alt="FGC"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEmail;
