import Styles from "../Styles/home.module.scss";
import line from "../Assets/Home/Background/line.webp";
import redirectlogo from "../Assets/Home/Background/start.webp";
import { Link, useNavigate } from "react-router-dom";

const HomeViewEn = () => {
  const navigate = useNavigate();

  return (
    <div className={Styles.enhomecontainer}>
      <div className={Styles.enhomelogo}>
      <img src="" style={{visibility:"hidden"}} />
      </div>

      <div className={Styles.encontainerline}>
        <img src={line} />
      </div>

      <div className={Styles.encontentcontainer}>
        <div className={Styles.encontenthead}>
          <div className={Styles.enselection}>
            <div>
              <p>WELCOME TO THE</p>
              <h4>ELITE ESPORTS</h4>
              <h3>BOOTCAMP</h3>
            </div>
            <div>
              <button onClick={() => navigate("/ar")}>Arabic</button>
            </div>
          </div>
        </div>

        <div className={Styles.encontentlist}>
          <ol>
            <li>
              Follow @QiddiyaGaming
              <br /> on <a href="https://x.com/QiddiyaGaming">X</a> &{" "}
              <a href="https://www.instagram.com/qiddiyagaming/">Instagram</a>
            </li>
            <li>
              Join the @QiddiyaGaming
              <a href="https://discord.com/invite/3bq2tHeZ"> Discord</a>
            </li>
            <li>COMPLETE ALL 3 Challenges</li>
            <li>
              Present your QR code to the <br /> concierge to earn your rewards
            </li>
          </ol>

          <ul>
            <h4>PSST ... hERE’S SOME INSIDER INTEL:</h4>
            <li>
              tHE TOP SCORING PLAYER ON THE <br /> LEADERBOARD WILL RECEIVE
              LOGITECH SWAG!
            </li>

            <li>
              tHE FIRST 3 PLAYERS TO REACH A total SCORE of 85 WILL UNLOCK A
              SECRET SUPPLY DROP!
            </li>
          </ul>
        </div>
      </div>

      <div className={Styles.encontactlinkbutton}>
        <Link to="/registration">
          <img src={redirectlogo} />
        </Link>
      </div>
    </div>
  );
};

export default HomeViewEn;
