import Styles from "../Styles/home.module.scss";
import line from "../Assets/Home/Background/linear.webp";
import redirectlogo from "../Assets/Home/Background/start.webp";
import { Link, useNavigate } from "react-router-dom";

const HomeViewAr = () => {
  const navigate = useNavigate();

  return (
    <div className={Styles.arhomecontainer}>
      <div className={Styles.arhomelogo}>
      <img src="" style={{visibility:"hidden"}} />
      </div>

      <div className={Styles.arcontainerline}>
        <img src={line} />
      </div>

      <div className={Styles.arcontentcontainer}>
        <div className={Styles.arcontenthead}>
          <div className={Styles.arselection}>
            <div>
              <p>WELCOME TO THE</p>
              <h4>ELITE ESPORTS</h4>
              <h3>BOOTCAMP</h3>
            </div>
            <div>
              <button onClick={() => navigate("/")}>English</button>
            </div>
          </div>
        </div>

        <div className={Styles.arcontentlist}>
          <ol>
            <li>
              تابع{" "}
              <a href="https://www.instagram.com/qiddiyagaming/">
                @QiddiyaGaming
              </a>{" "}
              على منصة <a href="https://x.com/QiddiyaGaming">X</a> و
              <br />
              إنستغرام
            </li>
            <li>
              انضم إلى ديسكورد{" "}
              <a href="https://discord.com/invite/3bq2tHeZ">@QiddiyaGaming</a>
            </li>
            <li>
              سيطر على البوتكامب عبر التفوق في جميع <br />
              التحديات الثلاثة
            </li>
            <li>
              قدم رمز QR الخاص بك إلى البواب لتحصل على <br />
              مكافآتك.
            </li>
          </ol>

          <ul>
            <h4>هل تريد تحديث معداتك ؟</h4>
            <li>
              اللاعب الحاصل على أعلى درجة يومياً سيحصل على بعض
              <br />
              من معدات LogitechG.
            </li>

            <li>
              أول 3 لاعبين يحصلون على مجموع درجات يزيد عن
              <br />
              200 سيحصلون على صندوق الإمدادات!
            </li>
          </ul>
        </div>
      </div>

      <div className={Styles.arcontactlinkbutton}>
        <Link to="/registration">
          <img src={redirectlogo} />
        </Link>
      </div>
    </div>
  );
};

export default HomeViewAr;
